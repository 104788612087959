import * as React from "react";
import * as PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as features from "../../../../../lib/feature-helpers";
import {isEnvironmentAdmin} from "../../../../../lib/user-environment-permissions-helpers";
import * as validateData from "../../../../../lib/validate-cdrs-data";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import Header from "../../../../Widgets/Editor/Header/";
import ActionButtons from "./ActionButtons";

class Details extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired,
        features: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        login: PropTypes.object.isRequired,
        parentState: PropTypes.object.isRequired,
        initialize: PropTypes.func.isRequired,
        onSetParentState: PropTypes.func.isRequired
    };

    public componentDidMount() {
        this.validateData();
    }

    public componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.parentState.data) !== JSON.stringify(prevProps.parentState.data)) {
            this.validateData();
        }
    }

    public render() {
        const actAsAdmin = isEnvironmentAdmin(this.props.login, this.props.location, this.props.config);
        const canEdit = this.props.parentState.mode === ":EDIT:";

        return <Card style={{marginTop: 48}}>
            <Header {...this.props} style={{height: 56}}
                rightIcon={!canEdit && actAsAdmin
                    ? <IconButton style={{paddingTop: 16}} onClick={() => this.props.onSetParentState({mode: ":EDIT:"})}>
                        <Tooltip title="Edit"><EditIcon /></Tooltip>
                    </IconButton>
                    : null}
            />
            <div style={{ padding: "0 24px 24px 24px" }}>
                {this.renderName(canEdit)}
                {this.renderCDRId()}
                {this.renderDescription(canEdit)}
                {this.renderAuthType()}
                {this.renderFHIRVersion()}
                {this.renderCDRVersion()}
            </div>
            <ActionButtons parentState={this.props.parentState} initialize={this.props.initialize} onSetParentState={this.props.onSetParentState} />
        </Card>;
    }

    private renderName = (canEdit) => {
        return <TextField
            name="name" value={this.props.parentState.data.name || ""}
            placeholder="Display name" label="Display Name" helperText={this.props.parentState.dataValidation.name}
            error={!!this.props.parentState.dataValidation.name} disabled={!canEdit}
            autoComplete="off" autoFocus={false} fullWidth
            classes={{ root: canEdit ? "" : "disabled-text-field" }}
            style={{ marginTop: 16 }}
            onChange={canEdit ? (event) => this.props.onSetParentState((prevParentState) => ({ data: { ...prevParentState.data, name: event.target.value } })) : undefined}
        />
    }

    private renderCDRId = () => {
        const featureSmileCdrCustomization = features.check(":SMILE_CDR_CUSTOMIZATION:", this.props.features.data);
        const label = featureSmileCdrCustomization? "Smile CDR ID": "FHIR CDR ID";
        return <TextField
            name="cdrId" value={this.props.parentState.data.cdrId || ""}
            placeholder={label} label={label}
            helperText={this.props.parentState.dataValidation.cdrId}
            error={!!this.props.parentState.dataValidation.cdrId}
            disabled fullWidth
            classes={{ root: "disabled-text-field" }}
            style={{ marginTop: 16 }}
        />
    }

    private renderDescription = (canEdit) => {
        return <TextField
            name="description" value={this.props.parentState.data.description || ""}
            placeholder="Description" label="Description" disabled={!canEdit}
            autoComplete="off" autoFocus={false} fullWidth multiline
            classes={{ root: canEdit ? "" : "disabled-text-field" }}
            style={{ marginTop: 16 }}
            onChange={canEdit ? (event) => this.props.onSetParentState((prevParentState) => ({ data: { ...prevParentState.data, description: event.target.value } })) : undefined}
        />
    }

    private renderAuthType = () => {
        const value = this.props.parentState.data.authenticationType === "NONE" ? "None" : "SMART on FHIR";
        return <TextField
            name="authType" value={value}
            placeholder={"Authentication type"} label={"Authentication Type"}
            disabled fullWidth
            classes={{ root: "disabled-text-field" }}
            style={{ marginTop: 16 }}
        />
    }

    private renderFHIRVersion = () => {
        let value = this.props.parentState.data.fhirVersion;
        switch (this.props.parentState.data.fhirVersion) {
            case "FHIR_DSTU2__1_0_2": value = "1.0.2"; break;
            case "FHIR_STU3__3_0_2": value = "3.0.2"; break;
            case "FHIR_R4__4_0_1": value = "4.0.1"; break;
        }
        return <TextField
            name="fhirVersion" value={value}
            placeholder={"FHIR version"} label={"FHIR version"}
            disabled fullWidth
            classes={{ root: "disabled-text-field" }}
            style={{ marginTop: 16 }}
        />
    }

    private renderCDRVersion = () => {
        const value = this.props.parentState.data.postgresCDR ? "PostgreSQL" : "MySQL";
        return <TextField
            name="cdrVersion" value={value}
            placeholder={"CDR Database"} label={"CDR Database"}
            disabled fullWidth
            classes={{ root: "disabled-text-field" }}
            style={{ marginTop: 16 }}
        />
    }

    private validateData = () => {
        const dataValidation = validateData.onEditDetails(this.props.parentState);
        this.props.onSetParentState({dataValidation});
    }
}

const mapStateToProps = (state, ownProps) => ({
    config: state.config,
    features: state.features,
    login: state.login,
    ...ownProps,
});
export default withRouter(connect(mapStateToProps)(Details));
