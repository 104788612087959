/* eslint-disable react/prop-types */
import * as React from "react";
import * as PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import {getFullDateDisplay, getNumberLocaleString} from "../../../../../../../lib/utils";
import "./style.less";
import {IconButton} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const Component: React.FunctionComponent<any> = props => {
    const transaction = props.transaction;
    const time = getFullDateDisplay(transaction.timestamp, props.ui.timeZone);
    const clientName = transaction.clientName || transaction.clientId;
    const userId = transaction.userId;
    const resourceType = transaction.fhirResource;
    const operationType = transaction.subtype;
    const latency = transaction.time;
    const queryString = transaction.queryString;

    return <ListItem disabled className={`ds-transactions-tt-item ${props.isActive ? "active" : ""}`} style={{fontSize: "14px", lineHeight: "14px", padding: "12px 0", opacity: 1}}>
        <div className="content">
            <div className="time">
                {time}
            </div>
            <div className="client">
                {clientName}
            </div>
            <div className="user">
                {userId}
            </div>
            <div className="resourceType">
                {resourceType}
            </div>
            <div className="operationType">
                {operationType}
            </div>
            <div className="latencyType">
                {latency ? `${getNumberLocaleString(latency)} ms` : "-"}
            </div>
            <div className="queryString">
                {queryString}
            </div>
            <IconButton
                disableRipple
                style={{ background: "none", maxHeight: "24px", padding: "0 12px"}}
                onClick={props.toggleItem}
            >
                {!props.isActive ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
        </div>
    </ListItem>;
};

Component.propTypes = {
    config: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    login: PropTypes.object.isRequired,
    muiTheme: PropTypes.object.isRequired,
    transaction: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    onSetState: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
    toggleItem: PropTypes.func.isRequired
};

export default Component;
