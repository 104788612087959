import * as React from "react";
import * as PropTypes from "prop-types";
// import $ from "jquery";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {delay} from "../../../../../lib/utils/";
import axiosWrapper from "../../../../../lib/axiosWrapper";
import withLegacyTheme from "../../../../../lib/hoc/with-legacy-theme";
import * as actions from "../../../../../redux/actions/";
import CenteredCircularProgress from "../../../../Widgets/CenteredCircularProgress/";
import TransactionsTable from "./TransactionsTable";
import "./style.less";

let moment = require("moment-timezone");

export class Transactions extends React.Component<any, any> {
    public static propTypes = {
        config: PropTypes.object.isRequired
    };

    public constructor(props) {
        super(props);

        this.state = {
            status: "init",
            allActivatedApps: [],
            allTransactions: {
                data: null,
                refreshing: false
            },
            transactions: {
                data: null,
                refreshing: false
            },
            filters: {
                pageSize: 50
            },
            timeframe: 0,
            sortBy: {
                time: true
            },
            showAll: false,
            startTime: null,
            startTimeInMs: null,
            endTime: null,
            endTimeInMs: null,
            transactionsPagination: {
                number: 0,
                size: 50,
                totalElements: 0
            },
        };
    }

    public componentDidMount() {
        this.setState({
            allActivatedApps: this.props.apps.all.data,
            status: "recalc",
            endTime: new Date(),
            endTimeInMs: new Date().getTime(),
            startTime: this.getStartDate(new Date()),
            startTimeInMs: this.getStartDate(new Date()).getTime()
        }, () => {
            this.transactions_init();
        });
    }

    public componentDidUpdate(_, prevState) {
        const filters_string = JSON.stringify(this.state.filters);
        const prevFilters_string = JSON.stringify(prevState.filters);
        const sortBy_string = JSON.stringify(this.state.sortBy);
        const prevSortBy_string = JSON.stringify(prevState.sortBy);

        if (this.state.timeframe !== prevState.timeframe) {
            if (this.state.timeframe >= 0) {
                this.setState(
                    {
                        status: "recalc",
                        endTime: new Date(),
                        endTimeInMs: new Date().getTime(),
                        startTime: this.getStartDate(new Date()),
                        startTimeInMs: this.getStartDate(new Date()).getTime()
                    },
                    () => {
                        this.transactions_init();
                    })
            }
        }
        if (this.state.timeframe === -1 && (prevState.endTime.valueOf() !== this.state.endTime.valueOf() || prevState.startTime.valueOf() !== this.state.startTime.valueOf())) {
            console.log("timeframe -1");
            this.setState(
                {
                    status: "recalc",
                    endTimeInMs: this.getDateInMs(this.state.endTime),
                    startTimeInMs: this.getDateInMs(this.state.startTime)
                },
                () => {
                    this.transactions_init();
                })
        }

        if ((filters_string !== prevFilters_string) || (sortBy_string !== prevSortBy_string)) {
            this.setState(
                {status: "recalc"},
                () => delay(250).then(() => this.setState(
                    () => {
                        this.transactions_init();
                    },
                    () => this.setState({status: "ready"})
                ))
            );
        }
    }

    public render() {
        if (this.state.transactions.data === null) {
            return <CenteredCircularProgress size={63} style={{padding: "24px"}}/>
        } else {
            return <div className="da-transactions-container" data-qa-da-transactions-container>
                <TransactionsTable {...this.props} state={this.state} onSetState={this.onSetState} transactions_init={this.transactions_init.bind(this)} setFilterItem={this.setFilterItem}/>
            </div>
        }
    }

    private onSetState = (state, cb) => this.setState(state, cb);

    private getStartDate(endTime) {
        let midnight = moment.tz(endTime - this.state.timeframe, this.props.ui.timeZone).startOf("day");
        return new Date(midnight.format());
    }

    private getDateInMs(date) {
        let midnight = moment.tz(moment(date).format("YYYY-MM-DD"), this.props.ui.timezone);
        return new Date(midnight.format()).getTime();
    }

    private runAnalyticsQuery(dataType, query) {
        return axiosWrapper(this.props.config.analyticsService, dataType, "POST", query)
    };

    private processTransactions(transactions) {
        let preparedData = transactions;

        return preparedData;
    }

    private transactions_init(page = 1) {
        page--;
        let query = this.transactions_preProcess(page);
        this.runAnalyticsQuery("da-all-transactions", query).then(res => {
            const allTransactions = {
                data: this.transactions_postProcess(res.data),
                refreshing: false
            };
            this.setState({
                transactions: {
                    data: this.processTransactions(allTransactions.data),
                    refreshing: false
                },
                allTransactions,
                transactionsPagination: res.data.page,
                status: "ready"
            });
        });
    }

    private transactions_preProcess(page) {
        return {
            startTime: this.state.startTimeInMs,
            endTime: this.state.endTimeInMs,
            daId: this.props.dataSources.selected.data.dataSourceId,
            accountId: this.props.dataSources.selected.data.accountId,
            environmentId: this.props.dataSources.selected.data.environmentId,
            page,
            size: this.state.filters.pageSize,
            sortBy: this.state.sortBy,
            operations: this.state.filters.operations,
            resources: this.state.filters.resources,
        };
    }

    private transactions_postProcess(rawData) {
        let preparedData = [];
        rawData.hits.hits.forEach((hit) => {
            let record: any = {};
            record.clientName = ""
            this.state.allActivatedApps.forEach((app) => {
                if (app.clientId === hit._source.client_id) {
                    record.clientName = app.name;
                }
            })
            record.clientId = hit._source.client_id || "";
            record.fhirResource = hit._source.fhir_resource || "";
            record.timestamp = hit._source.timestamp;
            record.interactionLevel = hit._source.tx_op_fhir_level || "";
            record.type = hit._source.tx_op || "";
            record.subtype = hit._source.tx_op_fhir || "";
            record.extendedOperationName = hit._source.tx_op_extended_name || "";
            record.userId = hit._source.user_id || "";
            record.sessionId = hit._source.session_id || "";
            record.gatewayRequestType = hit._source.gateway_request_type || "";
            record.accountId = hit._source.account_id || "";
            record.environmentId = hit._source.environment_id || "";
            record.gatewayId = hit._source.gateway_id || "";
            record.clientType = hit._source.client_type || "";
            record.time = hit._source.time || "";
            record.dataAdapterId = hit._source.data_adapter_id || "";
            record.queryString = hit._source.query_string || ""; // Add queryString property
            preparedData.push(record);
        })

        return preparedData;
    }

    private setFilterItem = (item, value) => {
        const filters = {...this.state.filters};
        filters[item] = value;
        if (item === "startTime") {
            delete filters.period;
        }
        this.setState({filters})
    }

}

const mapStateToProps = (state, ownProps) => ({...state, ...ownProps});
const mapDispatchToProps = (dispatch) => bindActionCreators({...actions}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withLegacyTheme()(Transactions));
