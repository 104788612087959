import * as React from "react";
import * as PropTypes from "prop-types";
import {ListItem, Paper, Tooltip, Typography, IconButton} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {getFullDateDisplay, getNumberLocaleString} from "../../../../../../../lib/utils";
import "./style.less";

const Component: React.FunctionComponent<any> = props => {
    const transaction = props.transaction;
    const time = getFullDateDisplay(transaction.timestamp, props.ui.timeZone);
    const clientName = transaction.clientName || transaction.clientId;
    const userId = transaction.userId;
    const resourceType = transaction.fhirResource;
    const operationType = transaction.subtype;
    const latency = transaction.time;
    const queryString = transaction.queryString;

    return <ListItem disabled className={`gw-transactions-tt-item ${props.isActive ? "active" : ""}`} style={{fontSize: "14px", lineHeight: "14px", padding: "12px 0", opacity: 1}}>
        <div className="content">
            <div className="time">
                {time}
            </div>
            <div className="client">
                {clientName}
            </div>
            <div className="user">
                {!!transaction.name && !!transaction.lastLogin
                    ? <Tooltip arrow placement="top" classes={{tooltip: "transactionUserTooltip"}}
                        title={<Paper style={{backgroundColor: "white", width: "250px", padding: "8px 16px", position: "relative"}}>
                            <Typography variant="subtitle2">Username:</Typography>
                            <Typography variant="caption">{userId}</Typography>
                            <Typography variant="subtitle2">Name:</Typography>
                            <Typography variant="caption">{transaction.name}</Typography>
                            <Typography variant="subtitle2">Last login:</Typography>
                            <Typography variant="caption">{getFullDateDisplay(transaction.lastLogin, props.ui.timeZone)}</Typography>
                            <div style={{position: "absolute", top: "10px", right: "25px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                                <span style={{display: "block", backgroundColor: "green", width: "10px", height: "10px", borderRadius: "20px"}}/>
                                <Typography variant="caption">{transaction.disabled ? "Inactive" : "Active"}</Typography>
                            </div>
                        </Paper>}>
                        <span style={{cursor: "pointer"}}>{userId}</span>
                    </Tooltip>
                    : <span style={{cursor: "pointer"}}>{userId}</span>}
            </div>
            <div className="resourceType">
                {resourceType}
            </div>
            <div className="operationType">
                {operationType}
            </div>
            <div className="latency">
                {latency ? `${getNumberLocaleString(latency)} ms` : "-"}
            </div>
            <div className="queryString">
                {queryString}
            </div>
            <IconButton
                disableRipple
                style={{ background: "none", maxHeight: "24px", padding: "0 12px"}}
                onClick={props.toggleItem}
            >
                {!props.isActive ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
        </div>
    </ListItem>;
};

Component.propTypes = {
    config: PropTypes.object.isRequired,
    isActive: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    login: PropTypes.object.isRequired,
    muiTheme: PropTypes.object.isRequired,
    toggleItem: PropTypes.func.isRequired,
    transaction: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    onSetState: PropTypes.func.isRequired
};

export default Component;
