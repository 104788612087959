/* eslint-disable react/prop-types */
import * as React from "react";
import * as PropTypes from "prop-types";
import {getFullDateDisplay, getPath} from "../../../../../../lib/utils/";
import TransactionItem from "./TransactionItem/";
import Table, {FilterByDate, FilterBy} from "../../../../../Widgets/Table";
import getEnvironmentName from "../../../../../../lib/getEnvironmentName";
import axiosWrapper from "../../../../../../lib/axiosWrapper";
import consoleLogger from "../../../../../../lib/consoleLogger";

const OPERATIONS_TYPE = [
    "READ",
    "VREAD",
    "SEARCH",
    "CREATE",
    "UPDATE",
    "PATCH",
    "DELETE",
    "HISTORY",
    "CAPABILITIES",
    "BATCH_TX",
    "EXTENDED",
]

const Component: React.FunctionComponent<any> = props => {
    const [active, setActive] = React.useState<number[]>([]);
    const exportCsv = () => {
        const accountName = props.accounts.selected.data.name;
        const environmentId = props.dataSources.selected.data.environmentId;
        const environmentName = getEnvironmentName(environmentId, props.environments);
        const dataSourceName = props.dataSources.selected.data.name;
        axiosWrapper(props.config.analyticsService, "gtw-export-csv", "POST", props.state.transactions.data, {}, "blob")
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                const fileName = `${accountName}_${environmentName}_${dataSourceName}_da-transactions-list-${getFullDateDisplay(Date.now(), props.ui.timeZone)}.csv`;
                const sanitizedFileName = encodeURI(fileName);
                link.setAttribute("download", sanitizedFileName);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                consoleLogger.log(error);
            });
    };
    let pagination = {
        goToPage: props.transactions_init,
        loading: props.state.status !== "ready",
        size: props.state.filters.pageSize,
        number: props.state.transactionsPagination.number + 1,
        totalElements: props.state.transactionsPagination.totalElements?.value ?? props.state.transactionsPagination.totalElements,
        setPageSize: size => props.setFilterItem("pageSize", size)
    };
    let transactions = getPath(props, "state.transactions.data") || [];

    const columns = [
        {
            size: 0.9,
            name: "time",
            label: "Time",
            sort: () => props.onSetState({sortBy: {["time"]: !getPath(props, "state.sortBy.time")}}),
            order: getPath(props, "state.sortBy.time")
        },
        {
            size: 0.9,
            name: "client",
            label: "Client",
            // sort: () => props.onSetState({sortBy: {["client"]: !getPath(props, "state.sortBy.client")}}),
            order: getPath(props, "state.sortBy.client")
        },
        {
            size: 1,
            name: "user",
            label: "User",
            sort: () => props.onSetState({sortBy: {["user"]: !getPath(props, "state.sortBy.user")}}),
            order: getPath(props, "state.sortBy.user")
        },
        {
            size: 0.9,
            name: "resourceType",
            label: "Resource",
            sort: () => props.onSetState({sortBy: {["resourceType"]: !getPath(props, "state.sortBy.resourceType")}}),
            order: getPath(props, "state.sortBy.resourceType")
        },
        {
            size: 0.5,
            name: "operationType",
            label: "Operation",
            sort: () => props.onSetState({sortBy: {["operationType"]: !getPath(props, "state.sortBy.operationType")}}),
            order: getPath(props, "state.sortBy.operationType")
        },
        {
            size: 0.4,
            name: "latency",
            label: "Latency",
            sort: () => props.onSetState({sortBy: {["latency"]: !getPath(props, "state.sortBy.latency")}}),
            order: getPath(props, "state.sortBy.latency")
        },
        {
            size: 1.5,
            name: "queryString",
            label: "Query String",
            order: getPath(props, "state.sortBy.queryString"),
            style: { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }
        },
        {
            size: '0 0 48px',
            name: "expand",
            label: "",
            style: { textAlign: "right" }
        }
    ];
    const transactionsList = transactions.map((transaction, index) => {
        const isActive = active.indexOf(index) >= 0;
        return <TransactionItem key={`${transaction.timestamp}-${index}`} {...props} transaction={transaction} isActive={isActive} toggleItem={() => toggleItem(index, active, setActive)}/>;
    });

    const filters = [
        {
            type: "component",
            value: <FilterByDate {...props} key="time_filter" setFilterItem={(type, value, timeframe) => updateDate(props, type, value, timeframe)}/>
        },
        {
            type: "component",
            value: <FilterBy {...props} key="operations" label="Operation" allItems={OPERATIONS_TYPE}
                filterKey="operations" setFilterItem={props.setFilterItem}/>
        },
    ];

    return <Table total={`Showing ${transactionsList.length} transactions`} rightFilters={filters} export={[{func: exportCsv, label: "Export CSV"}]} columns={columns} pagination={pagination}>
        {transactionsList}
    </Table>;
}

const toggleItem = (index, active, setActive) => {
    const indexOf = active.indexOf(index);
    const newActive = active.slice();
    indexOf >= 0 ? newActive.splice(indexOf, 1) : newActive.push(index);
    setActive(newActive);
}

const updateDate = (props, type, value, timeframe) => {
    if (type === "period") {
        props.onSetState({startTime: value.startTime, endTime: value.endTime, timeframe: -1});
    } else {
        props.onSetState({timeframe: timeframe});
    }
}

Component.defaultProps = {
    state: PropTypes.object.isRequired,
    onSetState: PropTypes.func.isRequired
};

export default Component;
